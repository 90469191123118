import { ISelectedTestCase } from '../../../interfaces';

export function searchTestCases(testCases: ISelectedTestCase[], search?: string): ISelectedTestCase[] {
    const searchTerm = search?.toLowerCase()?.trim() || '';
    return testCases.map((testCase) => {
        const visible =
            testCase.testName.toLowerCase().includes(searchTerm) ||
            testCase.tags.some((tag) => tag.toLowerCase().includes(searchTerm)) ||
            testCase.displayName.toLowerCase().includes(searchTerm);
        return {
            ...testCase,
            visible,
        };
    });
}
