import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, patchRequest, postRequest } from '../../api';
import { SliceStatus } from '../commons/enums';
import { getUrlFilterValue } from '../commons/getUrlPrams';
import { IFilterAutocomplete, IToggleColumn } from '../commons/interfaces';
import * as queryString from '../commons/queryString';
import { getInitialColumnVisibilityState, persistColumnVisibleStateInCookie } from '../commons/toggleableColumnsState';
import { AgentDialogActionType, AgentsColumnId } from './enums';
import { BuildAgent, IBuildAgentDialog, IBuildAgentSliceState, IBuildAgentsSlice, IFilters } from './interfaces';

export const getBuildAgents = createAsyncThunk<any, void, { state: any }>(
    'buildAgents/getBuildAgents',
    async (_args, thunkAPI) => {
        const state = thunkAPI.getState();
        const params: Record<string, string> = {};
        const filters = state.buildAgents.filters;

        for (const key of Object.keys(filters)) {
            if (filters[key as keyof IFilters].selected) {
                let paramsKey = key;
                if (key === 'unity') {
                    paramsKey = 'supportedTools.unity';
                } else if (key === 'xcode') {
                    paramsKey = 'supportedTools.xcode';
                } else if (key === 'git_info') {
                    paramsKey = 'agentInfo.git_info';
                } else if (key === 'version') {
                    paramsKey = 'agentInfo.version';
                }

                params[paramsKey] = filters[key as keyof IFilters].selected as string;
            }
        }

        return getRequest('/build-agents', params);
    },
);

export const getAvailableOptions = createAsyncThunk<any, void>(
    'buildAgents/getAvailableOptions',
    async (_args, thunkAPI) => {
        const params = getBuildAgentFiltersFieldParams();

        const response = await getRequest('/build-agents/available-options', params, thunkAPI);
        return response.options;
    },
);

export const registerBuildAgents = createAsyncThunk<any, { ip: string; port?: number; priority?: number }>(
    'buildAgents/registerBuildAgent',
    async (payload, thunkAPI) => {
        return postRequest('/build-agents', payload, thunkAPI);
    },
);

export const editBuildAgent = createAsyncThunk<
    any,
    { priority: number; enabled: boolean; buildAgentId: string; disabledReason?: string }
>('buildAgents/editBuildAgent', async (payload, thunkAPI) => {
    const { priority, enabled, buildAgentId, disabledReason } = payload;
    return patchRequest(`/build-agents/${buildAgentId}`, { priority, enabled, disabledReason }, thunkAPI);
});

export const deleteBuildAgent = createAsyncThunk<any, string, { state: IBuildAgentSliceState }>(
    'buildAgents/deleteBuildAgent',
    async (buildAgentId, thunkAPI) => {
        return deleteRequest(`/build-agents/${buildAgentId}`, thunkAPI);
    },
);

export const doMaintenanceCheckout = createAsyncThunk<any, Array<{ ip: string; port?: string }>>(
    'buildAgents/maintenance/checkout',
    async (agents, thunkAPI) => {
        return postRequest('/build-agents/maintenance/checkout', { agents }, thunkAPI);
    },
);

export const doMaintenanceRemove = createAsyncThunk<any, Array<{ ip: string; port?: string }>>(
    'buildAgents/maintenance/remove',
    async (agents, thunkAPI) => {
        return postRequest('/build-agents/maintenance/remove', { agents }, thunkAPI);
    },
);

export const rebootAgent = createAsyncThunk<any, string>('buildAgents/rebootAgent', async (agentAddress, thunkAPI) => {
    return postRequest('/build-agents/reboot', { agentAddress }, thunkAPI);
});

function getBuildAgentFiltersFieldParams(): Record<string, any> {
    const allFields = [
        'hostname',
        'state',
        'supportedProjects',
        'supportedTools.unity',
        'supportedTools.xcode',
        'agentInfo.git_info',
        'agentInfo.version',
    ];

    return {
        fields: allFields,
    };
}

const defaultColumnVisibility = {
    [AgentsColumnId.name]: true,
    [AgentsColumnId.priority]: false,
    [AgentsColumnId.state]: true,
    [AgentsColumnId.ip]: false,
    [AgentsColumnId.unityVersions]: true,
    [AgentsColumnId.projects]: true,
    [AgentsColumnId.lastSeen]: false,
    [AgentsColumnId.xcode]: true,
    [AgentsColumnId.ndk]: false,
    [AgentsColumnId.sdk]: false,
    [AgentsColumnId.cocoapods]: false,
    [AgentsColumnId.svn]: false,
    [AgentsColumnId.memory]: false,
    [AgentsColumnId.disk]: false,
    [AgentsColumnId.os]: false,
    [AgentsColumnId.cpu]: false,
    [AgentsColumnId.commit]: false,
    [AgentsColumnId.updatedBy]: false,
    [AgentsColumnId.actions]: true,
    [AgentsColumnId.version]: false,
};

const columnInitialVisibleState = getInitialColumnVisibilityState('buildAgentVisibleColumns', defaultColumnVisibility);

const initialState: IBuildAgentsSlice = {
    buildAgents: [],
    error: undefined,
    status: SliceStatus.idle,
    totalCount: 0,
    filters: initializeFilters(),
    highlightBuildAgents: [],
    buildAgentDialog: {
        open: false,
        action: AgentDialogActionType.register,
    },
    agentMaintenanceDialog: {
        open: false,
        action: AgentDialogActionType.maintenance,
    },
    columns: [
        {
            id: AgentsColumnId.state,
            label: 'State',
            align: 'left',
            minWidth: 120,
            width: '120px',
            visible: columnInitialVisibleState[AgentsColumnId.state],
        },
        {
            id: AgentsColumnId.name,
            label: 'Name',
            width: '10%',
            align: 'left',
            visible: columnInitialVisibleState[AgentsColumnId.name],
            disabled: true,
        },
        {
            id: AgentsColumnId.priority,
            label: 'Priority',
            align: 'left',
            width: '5%',
            visible: columnInitialVisibleState[AgentsColumnId.priority],
        },
        {
            id: AgentsColumnId.ip,
            label: 'IP',
            align: 'left',
            width: '10%',
            visible: columnInitialVisibleState[AgentsColumnId.ip],
        },
        {
            id: AgentsColumnId.projects,
            label: 'Projects',
            align: 'left',
            width: '20%',
            visible: columnInitialVisibleState[AgentsColumnId.projects],
        },
        {
            id: AgentsColumnId.unityVersions,
            label: 'Unity',
            align: 'left',
            width: '15%',
            visible: columnInitialVisibleState[AgentsColumnId.unityVersions],
        },
        {
            id: AgentsColumnId.xcode,
            label: 'Xcode',
            align: 'left',
            width: '10%',
            visible: columnInitialVisibleState[AgentsColumnId.xcode],
        },
        {
            id: AgentsColumnId.ndk,
            label: 'Ndk',
            align: 'left',
            width: '5%',
            visible: columnInitialVisibleState[AgentsColumnId.ndk],
        },
        {
            id: AgentsColumnId.sdk,
            label: 'Sdk',
            align: 'left',
            width: '5%',
            visible: columnInitialVisibleState[AgentsColumnId.sdk],
        },
        {
            id: AgentsColumnId.jdk,
            label: 'JDK',
            align: 'left',
            width: '5%',
            visible: columnInitialVisibleState[AgentsColumnId.jdk],
        },
        {
            id: AgentsColumnId.cocoapods,
            label: 'Cocoapods',
            align: 'left',
            width: '5%',
            visible: columnInitialVisibleState[AgentsColumnId.cocoapods],
        },
        {
            id: AgentsColumnId.svn,
            label: 'Svn',
            align: 'left',
            width: '5%',
            visible: columnInitialVisibleState[AgentsColumnId.svn],
        },
        {
            id: AgentsColumnId.memory,
            label: 'Memory',
            align: 'left',
            width: '5%',
            visible: columnInitialVisibleState[AgentsColumnId.memory],
        },
        {
            id: AgentsColumnId.cpu,
            label: 'CPU',
            width: '5%',
            align: 'left',
            visible: columnInitialVisibleState[AgentsColumnId.cpu],
        },
        {
            id: AgentsColumnId.disk,
            label: 'Free Disk Space (GB)',
            align: 'left',
            width: '10%',
            visible: columnInitialVisibleState[AgentsColumnId.disk],
        },
        {
            id: AgentsColumnId.os,
            label: 'OS',
            align: 'left',
            width: '7%',
            visible: columnInitialVisibleState[AgentsColumnId.os],
        },
        {
            id: AgentsColumnId.commit,
            label: 'Commit Hash',
            align: 'left',
            width: '12%',
            visible: columnInitialVisibleState[AgentsColumnId.actions],
        },
        {
            id: AgentsColumnId.version,
            label: 'Version',
            align: 'left',
            width: '10%',
            visible: columnInitialVisibleState[AgentsColumnId.version],
        },
        {
            id: AgentsColumnId.lastSeen,
            label: 'Last Seen',
            align: 'left',
            width: '10%',
            visible: columnInitialVisibleState[AgentsColumnId.lastSeen],
        },
        {
            id: AgentsColumnId.updatedBy,
            label: 'Updated By',
            align: 'center',
            minWidth: 100,
            width: '100px',
            visible: columnInitialVisibleState[AgentsColumnId.updatedBy],
        },
        {
            id: AgentsColumnId.actions,
            label: 'Actions',
            align: 'left',
            minWidth: 105,
            width: '105px',
            visible: columnInitialVisibleState[AgentsColumnId.actions],
        },
    ],
};

export const buildAgentSlice = createSlice({
    name: 'buildAgents',
    initialState,
    reducers: {
        setAgentsColumnVisibility: (state, action: PayloadAction<{ columnId: AgentsColumnId; value: boolean }>) => {
            const { columnId, value } = action.payload;
            const columnIndex = state.columns.findIndex((column) => column.id === columnId);
            state.columns[columnIndex].visible = value;
            persistColumnVisibleStateInCookie('buildAgentVisibleColumns', state.columns);
        },
        setStatus: (state, action: PayloadAction<SliceStatus>) => {
            state.status = action.payload;
        },
        setFilterHostname: (state, action: PayloadAction<string | null>) => {
            state.filters.hostname.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterState: (state, action: PayloadAction<string | null>) => {
            state.filters.state.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterUnity: (state, action: PayloadAction<string | null>) => {
            state.filters.unity.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterXcode: (state, action: PayloadAction<string | null>) => {
            state.filters.xcode.selected = action.payload;
            setUrlParamsFromState(state);
        },
        initializeAllFilters: (state) => {
            state.filters = initializeFilters();
        },
        clearAllFilters: (state) => {
            state.filters.hostname.selected = null;
            state.filters.state.selected = null;
            state.filters.unity.selected = null;
            state.filters.xcode.selected = null;
            state.filters.supportedProjects.selected = null;
            state.filters.git_info.selected = null;
            state.filters.version.selected = null;
            setUrlParamsFromState(state);
        },
        setHighlightBuildAgents: (state, action: PayloadAction<string[]>) => {
            state.highlightBuildAgents = action.payload;
        },
        removeHighlightBuildAgent: (state, action: PayloadAction<string>) => {
            state.highlightBuildAgents = state.highlightBuildAgents.filter(
                (buildAgentId) => buildAgentId !== action.payload,
            );
        },
        setFilterSupportedProject: (state, action: PayloadAction<string | null>) => {
            state.filters.supportedProjects.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterGifInfo: (state, action: PayloadAction<string | null>) => {
            state.filters.git_info.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterVersion: (state, action: PayloadAction<string | null>) => {
            state.filters.version.selected = action.payload;
            setUrlParamsFromState(state);
        },
        toggleShowBuildAgentDialog: (
            state,
            action: PayloadAction<{ buildAgent?: BuildAgent; action: AgentDialogActionType }>,
        ) => {
            state.buildAgentDialog.open = !state.buildAgentDialog.open;
            state.buildAgentDialog.action = action.payload.action;
            state.buildAgentDialog.buildAgent = action.payload?.buildAgent;
        },
        toggleShowMaintenanceDialog: (
            state,
            action: PayloadAction<{
                buildAgent?: BuildAgent;
                action: AgentDialogActionType;
            }>,
        ) => {
            state.agentMaintenanceDialog.open = !state.agentMaintenanceDialog.open;
            state.agentMaintenanceDialog.action = action.payload.action;
            state.agentMaintenanceDialog.buildAgent = action.payload?.buildAgent;
            state.agentMaintenanceDialog.agentsOnMachine = state.buildAgents?.filter(
                (agent) => agent.ip === action.payload?.buildAgent?.ip,
            );
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBuildAgents.pending, (state) => {
                if (!state.buildAgents?.length) {
                    state.status = SliceStatus.loading;
                }
            })
            .addCase(
                getBuildAgents.fulfilled,
                (state, action: PayloadAction<{ buildAgents: []; totalCount: number }>) => {
                    state.status = SliceStatus.loaded;
                    state.buildAgents = action.payload.buildAgents;
                },
            )
            .addCase(getAvailableOptions.pending, (state) => {
                state.filters.hostname.loading = true;
                state.filters.state.loading = true;
                state.filters.supportedProjects.loading = true;
                state.filters.unity.loading = true;
                state.filters.xcode.loading = true;
                state.filters.git_info.loading = true;
                state.filters.version.loading = true;
            })
            .addCase(getAvailableOptions.fulfilled, (state, action) => {
                const filterFields = Object.keys(action.payload) as unknown as keyof IFilters;
                for (const field of filterFields) {
                    const key = field.includes('.') ? field.split('.').pop() : field;

                    state.filters[key as keyof IFilters].options = action.payload[field] || [];
                    state.filters[key as keyof IFilters].loading = false;
                }
            });
    },
});

function setUrlParamsFromState(buildState: IBuildAgentsSlice): void {
    const params = queryString.parse(window.location.search);

    for (const filter in buildState.filters) {
        if (buildState.filters[filter as keyof IFilters].selected) {
            params[filter] = buildState.filters[filter as keyof IFilters].selected;
        } else {
            delete params[filter];
        }
    }

    const stringParams = queryString.stringify(params);
    const url = `/agents${stringParams ? `?` : ''}${stringParams}`;

    window.history.pushState(null, `set filters filters`, url);
}

export const selectAllBuildAgents = (state: IBuildAgentSliceState): any[] => state.buildAgents.buildAgents;
export const selectFilterHostname = (state: { buildAgents: IBuildAgentsSlice }): IFilterAutocomplete =>
    state.buildAgents.filters.hostname;
export const selectFilterState = (state: { buildAgents: IBuildAgentsSlice }): IFilterAutocomplete =>
    state.buildAgents.filters.state;
export const selectFilterUnity = (state: { buildAgents: IBuildAgentsSlice }): IFilterAutocomplete =>
    state.buildAgents.filters.unity;
export const selectFilterXcode = (state: { buildAgents: IBuildAgentsSlice }): IFilterAutocomplete =>
    state.buildAgents.filters.xcode;
export const selectFilterProject = (state: { buildAgents: IBuildAgentsSlice }): IFilterAutocomplete =>
    state.buildAgents.filters.supportedProjects;
export const selectFilterGitInfo = (state: { buildAgents: IBuildAgentsSlice }): IFilterAutocomplete =>
    state.buildAgents.filters.git_info;
export const selectFilterVersion = (state: IBuildAgentSliceState): IFilterAutocomplete =>
    state.buildAgents.filters.version;
export const selectBuildAgentPageStatus = (state: IBuildAgentSliceState): SliceStatus => state.buildAgents.status;
export const selectBuildAgentColumns = (state: IBuildAgentSliceState): Array<IToggleColumn<AgentsColumnId>> =>
    state.buildAgents.columns;
export const selectBuildAgentDialog = (state: IBuildAgentSliceState): IBuildAgentDialog =>
    state.buildAgents.buildAgentDialog;
export const selectAgentMaintenanceDialog = (state: IBuildAgentSliceState) => state.buildAgents.agentMaintenanceDialog;
export const selectAmountOfActiveAgentsFilters = (state: IBuildAgentSliceState): number =>
    Object.values(state.buildAgents.filters).reduce((total: number, filter: IFilterAutocomplete) => {
        return filter.selected ? total + 1 : total;
    }, 0);
export const selectHighlightBuildAgents = (state: IBuildAgentSliceState): string[] =>
    state.buildAgents.highlightBuildAgents;

export const {
    setAgentsColumnVisibility,
    setStatus,
    setFilterHostname,
    setFilterState,
    setFilterUnity,
    setFilterXcode,
    setFilterSupportedProject,
    setFilterGifInfo,
    setFilterVersion,
    setHighlightBuildAgents,
    toggleShowBuildAgentDialog,
    toggleShowMaintenanceDialog,
    removeHighlightBuildAgent,
    clearAllFilters,
    initializeAllFilters,
} = buildAgentSlice.actions;

export default buildAgentSlice.reducer;

export function initializeFilters(): IFilters {
    return {
        hostname: { options: [], selected: getUrlFilterValue('hostname') },
        state: { options: [], selected: getUrlFilterValue('state') },
        supportedProjects: { options: [], selected: getUrlFilterValue('supportedProjects') },
        unity: { options: [], selected: getUrlFilterValue('unity') },
        xcode: { options: [], selected: getUrlFilterValue('xcode') },
        git_info: { options: [], selected: getUrlFilterValue('git_info') },
        version: { options: [], selected: getUrlFilterValue('version') },
    };
}
